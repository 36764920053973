import React from "react"
import {Layout, Tabs} from "antd"
import type {TabsProps} from "antd"
import {useOutletContext} from "react-router-dom"

import {HeaderComponent} from "core/src/components/Header";
import {CartComponent} from "packages/src/syncs/main_sync";
import {CartDiscountsComponent} from "packages/src/syncs/discounts_sync";

export const SyncPage = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any }>()
    const items: TabsProps['items'] = [
        {
            key: 'main',
            label: 'Общая синхронизация',
            children: <CartComponent/>
        },
        {
            key: 'discounts',
            label: 'Синхронизация акций',
            children: <CartDiscountsComponent/>
        }
    ];

    return <>
        <Layout className={"h-100"} style={{padding: "1.5rem"}}>
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: "Синхронизация",
                }
            ]}/>
            <Tabs items={items}/>
        </Layout>
    </>
}

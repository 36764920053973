import React from "react";
import {FileSyncOutlined, BookOutlined} from "@ant-design/icons"
import {SyncPage} from "packages/src/Sync";
import {ReservePage, ReserveLoader} from "packages/src/Reserve";
import { redirect } from "react-router-dom";
import dayjs from "dayjs"

export default [
    {
        name: "Berezka",
        application: {
            init: () => {
            },
            menu: (items: any[], appData: any) => {
                // items.push({
                //     key: "reserve",
                //     icon: <BookOutlined/>,
                //     label: "Бронирование столов",
                //     permissions: []
                // })
                items.push({
                    key: "sync",
                    icon: <FileSyncOutlined/>,
                    label: "Синхронизация",
                    permissions: []
                })
                items.push({
                    type: 'divider',
                    permissions: []
                })


            }
        },
        routes: {
            application: (routeData: any) => {
                routeData.children.push({
                    id: "sync",
                    path: "sync",
                    element: <SyncPage/>
                })
                routeData.children.push({
                    id: "reserve",
                    path: "reserve",
                    children: [
                        {
                            id: "reserveDefault",
                            index: true,
                            loader:  () => {
                                const date = dayjs(new Date()).format("YYYY-MM-DD")
                                return redirect(date);
                            }
                        },
                        {
                            id: "reserveDay",
                            path: ":day",
                            element: <ReservePage/>,
                            loader: ReserveLoader
                        }
                    ]

                })
            }
        }
    },
]
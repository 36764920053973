import React from "react";
import {Request} from "kaiju.core";
import {Button, Card, Divider} from "antd";
import ReactJson from "@microlink/react-json-view";


export const CartComponent: React.FC = () => {
    const [loading, setLoading] = React.useState<boolean>(false)
    const [data, setData] = React.useState<any>({})
    const onProcess = () => {
        setLoading(true)
        setData({})
        Request("Sync.start", {}).then(({result}) => {
            setData(result)
            setLoading(false)
        })
    }
    return (
        <Card>
            <Button
                onClick={onProcess} loading={loading} type={"primary"}
                ghost> Синхронизировать</Button>
            <Divider>Результат</Divider>
            <ReactJson src={data} displayDataTypes={false} displayObjectSize={false} name={null}/>
        </Card>
    )
}

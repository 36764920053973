import React from "react"
import {Layout, Switch, Flex, Card, Tabs} from "antd"
import {useLoaderData, useNavigate, useOutletContext, useParams} from "react-router-dom"
import {Request} from "kaiju.core";
import {EditReservePage} from "./EditReserve"
import {HeaderComponent} from "core/src/components/Header";
import dayjs from 'dayjs';


interface SelectionData {
    selections: any[]
}

const getColour = (table: any) => {
    if (table.isBlocked) {
        return "rgb(137,137,137)"
    }
    if (table.isBooked) {
        return "rgba(250,154,75,0.4)"
    }

    return "rgba(255,255,255,0)"
}

const getDate = (num: number) => {
    const date = new Date()
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + num)
    return date
}

interface Params {
    date: string,
    appId: string,
}

export const ReservePage = () => {
    const {breadcrumbs} = useOutletContext<{ breadcrumbs: any }>()
    const params = useParams<keyof Params>() as Params

    const contentData: SelectionData = useLoaderData() as SelectionData
    const [tableId, setTableId] = React.useState<string | undefined>()
    const days = [...Array(14).keys()]
    const navigate = useNavigate();

    return <>
        <Layout className={"h-100"} style={{padding: "1.5rem"}}>
            <HeaderComponent breadcrumbs={[
                ...breadcrumbs,
                {
                    title: "Бронирование столов",
                }
            ]}/>
            <h2>Бронирование столов</h2>

            <Tabs
                type="card"
                defaultActiveKey={params.date}
                onTabClick={(key: string)=> {
                    navigate("/application/" + params.appId + "/reserve/" + key)
                }}
                items={days.map((num: number) => {
                    const date = getDate(num)
                    const id = dayjs(date).format("YYYY-MM-DD")
                    return {
                        label: <span style={{fontSize: 20}}>{date.toLocaleDateString().slice(0, 5)}</span>,
                        key: id,

                        contentData: <>{num}</>
                    };
                })}
            />
            <Card style={{width: 810}}>
                ТУТ БУДЕТ МЕРОПРИЯТИЕ
            </Card>

            {contentData.selections.map((el: any) => {
                return <div style={{width: 810}}>
                    <Flex align={"center"} justify={"space-between"}>
                        <h3>{el.name}</h3>
                        <span>Активность зоны: <Switch/></span>
                    </Flex>
                    <Card style={{
                        marginBottom: 20,
                        // border: '1px solid grey',
                        height: el.canvas.height,
                        width: el.canvas.width,
                        position: "relative",
                        background: "white"
                    }}>
                        {el.tables.map((table: any) => {
                            if (!table.schema) return <></>

                            return <div
                                style={{
                                    border: "1px solid grey",
                                    cursor: "pointer",
                                    background: getColour(el),
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    position: "absolute",
                                    height: table.schema.height,
                                    width: table.schema.width,
                                    zIndex: table.schema.z / 100,
                                    top: table.schema.x,
                                    left: table.schema.y,
                                }}
                                onClick={() => {
                                    setTableId(table.id)
                                }}
                            ><b>{table.number}</b></div>
                        })}

                    </Card>
                </div>
            })}
            {tableId &&
                <EditReservePage tableId={tableId} onClose={() => {
                    setTableId(undefined)
                }} isOpen={!!tableId}/>
            }
        </Layout>
    </>
}


export const ReserveLoader = async (props: any) => {
    return await Request('Reserve.restaurant_sections', {}).then((resp: any) => {
        return resp.result
    })
};


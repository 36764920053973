import React from "react"
import {Drawer} from "antd"
import {useLoaderData, useOutletContext} from "react-router-dom"
import {Request} from "kaiju.core";

import {HeaderComponent} from "core/src/components/Header";

interface Props {
    tableId: string,
    onClose: any,
    isOpen: boolean
}

export const EditReservePage: React.FC<Props> = (props) => {
    

    return <>
        <Drawer title={<h3>Стол: {props.tableId}</h3>}
                width={1000}
                onClose={props.onClose}
                open={props.isOpen}
        >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </Drawer>
    </>
}
